import { notEmpty } from "@product/scmp-sdk";
import type { FastifyRequest } from "fastify";
import createMatcher from "feather-route-matcher";

import type { AppBarAdStatus, AppBarVariant } from "~/components/app-bar/helpers";
import type { Application } from "~/components/app-initializer/helpers";
import { SubSectionList } from "~/components/story-style/consts";
import * as data from "~/data";
import { config } from "~/data";
import { Edition } from "~/lib/edition/data"; // This is for preventing server restart because of changing hooks
import { page as article, pathname as articlePathname } from "~/pages/article";
import { page as author, pathname as authorPathname } from "~/pages/author";
import { page as authors, pathname as authorsPathname } from "~/pages/authors";
import { page as home } from "~/pages/home";
import { page as live, pathname as livePathname } from "~/pages/live";
import { page as menu, pathname as menuPathname } from "~/pages/menu";
import { page as newsletters, pathname as newslettersPathname } from "~/pages/newsletters";
import { page as page, pathname as pagePathname } from "~/pages/page";
import { page as plus, pathname as plusPathname } from "~/pages/plus";
import { page as podcast, pathname as podcastPathname } from "~/pages/podcast";
import { page as podcasts, pathname as podcastsPathname } from "~/pages/podcasts";
import { page as posties, pathname as postiesPathname } from "~/pages/posties";
import { page as posties404, pathname as posties404Pathname } from "~/pages/posties/404";
import { page as postiesEvent, pathname as postiesEventPathname } from "~/pages/posties/event";
import { page as postiesEvents, pathname as postiesEventsPathname } from "~/pages/posties/events";
import { page as postiesKids, pathname as postiesKidsPathname } from "~/pages/posties/kids";
import {
  page as postiesParents,
  pathname as postiesParentsPathname,
} from "~/pages/posties/parents";
import {
  page as postiesSection,
  pathname as postiesSectionPathname,
} from "~/pages/posties/section-index";
import { page as postiesVideo, pathname as postiesVideoPathname } from "~/pages/posties/video";
import { page as printArticle, pathname as printArticlePathname } from "~/pages/print-article";
import { page as search, pathname as searchPathname } from "~/pages/search";
import { page as section, pathname as sectionPathname } from "~/pages/section";
import { getSectionRelatedSectionsQueueName } from "~/pages/section/helpers";
import { page as spotlight } from "~/pages/spotlight";
import {
  page as storyStyle,
  staticPathname as storyStyleStaticPathname,
} from "~/pages/story-style";
import { page as topic, pathname as topicPathname } from "~/pages/topic";
import { page as topics, pathname as topicsPathname } from "~/pages/topics";

import { ShouldForceV2RoutesCookieKey } from "./consts";

const pages = {
  article,
  author,
  authors,
  home,
  live,
  menu,
  newsletters,
  page,
  plus,
  podcast,
  podcasts,
  posties,
  posties404,
  postiesEvent,
  postiesEvents,
  postiesKids,
  postiesParents,
  postiesSection,
  postiesVideo,
  printArticle,
  search,
  section,
  spotlight,
  storyStyle,
  topic,
  topics,
};

type StaticRouteFunction = (context: {
  params: Record<string, string>;
  query: Record<string, string>;
}) => {
  asPath?: string;
  cacheMaxAge?: number;
  pathname: string;
  query?: Record<string, string>;
};

const statics: Record<string, StaticRouteFunction> = {
  "/": () => ({
    ...pages.home.route({ edition: Edition.International, slide: "main" }),
    cacheMaxAge: 60,
  }),
  "/asia": () => ({
    ...pages.home.route({ edition: Edition.Asia, slide: "main" }),
    cacheMaxAge: 60,
  }),
  "/hk": () => ({
    ...pages.home.route({ edition: Edition.HongKong, slide: "main" }),
    cacheMaxAge: 60,
  }),
  // Treat drupal video sections as sections since content service does not include video section
  "/scmp-films": () =>
    pages.section.route({ entityId: data.section.scmpFilms.entityId, slide: "main" }),
  "/scmp-spotlight": () => pages.spotlight.route({ page: "spotlight" }),
  [`${searchPathname}/:value?`]: context =>
    pages.search.route({ value: context.query.value ?? context.params.value }),
  "/series": () => pages.spotlight.route({ page: "series" }),
  ...SubSectionList.reduce<Record<string, StaticRouteFunction>>((staticsRoutes, subSection) => {
    staticsRoutes[`${storyStyleStaticPathname}/${subSection}`] = () => ({
      ...pages.storyStyle.route({ subSection }),
      cacheMaxAge: 60,
    });
    return staticsRoutes;
  }, {}),
  "/talking-post": () => pages.section.route({ entityId: "511121", slide: "main" }),
  [authorsPathname]: context => pages.authors.route({ char: context.query.char }),
  [livePathname]: () => ({ ...pages.live.route({}), cacheMaxAge: 60 }),
  [menuPathname]: () => pages.menu.route({}),
  [newslettersPathname]: () => pages.newsletters.route({}),
  [plusPathname]: () => ({ ...pages.plus.route({}), cacheMaxAge: 60 }),
  [podcastsPathname]: () => pages.podcasts.route({}),
  [posties404Pathname]: () => pages.posties404.route(),
  [postiesEventsPathname]: () => ({ ...pages.postiesEvents.route({}), cacheMaxAge: 60 }),
  [postiesKidsPathname]: () => ({ ...pages.postiesKids.route({}), cacheMaxAge: 60 }),
  [postiesParentsPathname]: () => ({ ...pages.postiesParents.route({}), cacheMaxAge: 60 }),
  [postiesPathname]: () => pages.posties.route({}),
  [postiesVideoPathname]: () => ({ ...pages.postiesVideo.route({}), cacheMaxAge: 60 }),
  [topicsPathname]: context => pages.topics.route({ char: context.query.char }),
};

export function isStaticRoute(url: string) {
  return Object.keys(routes.statics).some(staticRoute => staticRoute.startsWith(url));
}

type DynamicRouteFunctionContext = {
  appBarAdStatus?: AppBarAdStatus;
  appBarVariant?: AppBarVariant;
  application?: Application;
  entityUuid?: string;
  fullSectionPath?: string[];
  isBot?: string;
  matchedPrefix: OrUndefined<string>;
  matchedSuffix: OrUndefined<string>;
  name?: string | null;
  params: Record<string, string>;
  parentSection?: string | null;
  query: Record<string, string>;
};

type DynamicRouteFunctionResult = {
  pathname: string;
  query?: Record<string, string>;
};

type DynamicRouteFunction = {
  (entityId: string, context?: DynamicRouteFunctionContext): DynamicRouteFunctionResult;
};

interface DynamicRoute {
  cacheMaxAge?: number;
  pathnames: string[];
  prefix?: string[];
  route: DynamicRouteFunction;
  suffix?: string[];
}

const dynamics: Record<string, DynamicRoute> = {
  Article: {
    pathnames: [articlePathname, printArticlePathname],
    prefix: [...pages.printArticle.prefix],
    route(entityId, context) {
      const matchedPrefix = context?.matchedPrefix;
      const commentID = context?.query.commentID;
      const entityUuid = context?.entityUuid ?? "";
      const appBarVariant = context?.appBarVariant;
      const application = context?.application;
      const isBot = context?.isBot;

      return matchedPrefix && pages.printArticle.prefix.includes(matchedPrefix)
        ? pages.printArticle.route({ entityId })
        : pages.article.route({
            appBarVariant,
            application,
            commentID,
            entityId,
            entityUuid,
            isBot,
          });
    },
  },
  Author: {
    cacheMaxAge: 60,
    pathnames: [authorPathname],
    route(entityId) {
      return pages.author.route({ entityId });
    },
  },
  Newsletter: {
    pathnames: [newslettersPathname, "/newsletter"],
    route(entityId) {
      return pages.newsletters.route({ entityId });
    },
  },
  Page: {
    pathnames: [pagePathname],
    route(entityId, context) {
      const appBarVariant = context?.appBarVariant;
      const application = context?.application;
      return pages.page.route({ appBarVariant, application, entityId });
    },
  },
  Podcast: {
    pathnames: [podcastPathname],
    route(entityId) {
      return pages.podcast.route({ entityId });
    },
  },
  PodcastSection: {
    pathnames: [podcastPathname],
    route(entityId) {
      return pages.podcast.route({ entityId });
    },
  },
  PostiesEvent: {
    pathnames: [postiesEventPathname],
    route(entityId) {
      return pages.postiesEvent.route({ entityId });
    },
  },
  Section: {
    cacheMaxAge: 60,
    pathnames: [sectionPathname, postiesSectionPathname],
    route(entityId, context) {
      const matchedSuffix = context?.matchedSuffix;
      const application = context?.application;
      const appBarVariant = context?.appBarVariant;
      const appBarAdStatus = context?.appBarAdStatus;
      const name = context?.name;
      const parentSection = context?.parentSection;
      const fullSectionPath = context?.fullSectionPath ?? [];

      if (parentSection === data.section.posties.read.entityUuid && name) {
        return pages.postiesSection.route({ application, entityId, name });
      }

      return pages.section.route({
        appBarAdStatus,
        appBarVariant,
        application,
        entityId,
        relatedSectionsQueueName: getSectionRelatedSectionsQueueName(fullSectionPath),
        slide: pages.section.slideFromSuffix(matchedSuffix),
      });
    },
    suffix: [...pages.section.suffix],
  },
  Topic: {
    cacheMaxAge: 60,
    pathnames: [topicPathname],
    route(entityId) {
      return pages.topic.route({ entityId });
    },
  },
  Video: {
    cacheMaxAge: 60,
    pathnames: [postiesVideoPathname],
    route(entityId) {
      return pages.postiesVideo.route({ entityId });
    },
  },
};

// These redirects are scoped to the pwa application level for server side redirects.
// Client side should not need to handle these as there should not be any route define with old path.
const applicationRedirects = {
  "/frontpage/hk": "/hk",
  "/frontpage/international": "/",
  "/home": "/",
  "/newsletter": "/newsletters",
  "/us": "/",
};

// These redirects are defined in the drupal.
// Client side should route these paths to server to resolve for the actual redirect value.
const drupalRedirects = [
  "/topics/coronavirus-outbreak",
  "/topics/coronavirus-pandemic",
  "/topics/explorers-tomorrow",
  "/comment/harrys-view",
];

export function isDrupalRedirect(url: string) {
  return drupalRedirects.some(redirect => url.startsWith(redirect));
}

// These patterns are handled by different applications with routing at ingress
const internals = [
  "/sport/racing",
  "/infographic",
  "/photos",
  "/video",
  "/cooking",
  "/yp",
  "/china-internet-report",
  "/knowledge",
];

// These patterns that belongs to external are specially handled by pwa internally
const excludedInternals = ["/knowledge/us-china-relations", "/knowledge/china-macro-economy"];

export function isInternalDomainRoute(url: string) {
  return (
    internals.some(internal => url.startsWith(internal)) &&
    !excludedInternals.some(excluded => url.startsWith(excluded))
  );
}

const rejects = ["/inkstone"];

const homePageRoute = {
  "/asia-beta-homepage": true,
  "/asia-homepage": true,
  "/hk-beta-homepage": true,
  "/hk-homepage": true,
  "/int-beta-homepage": true,
  "/int-homepage": true,
};

// Migrated is routes that v1 has migrated to v2
// Refs: https://www.npmjs.com/package/feather-route-matcher
const migratedRouteMatcher = createMatcher({
  ...homePageRoute,
  "/author(/*)": true,
  "/authors": true,
  "/newsletter(s)(/*)": true,
  "/plus(/*)": true,
  "/posties(/*)": true,
  "/search(/*)": true,
  "/story/style(/*)": true,
  "/topic(/*)": true,
  "/topics(/*)": true,
});

export function isMigratedRoute(url: string, cookies: FastifyRequest["cookies"] = {}) {
  if (config.general.bypassMigrateRouteChecking) return true;
  const shouldForceV2Routes = cookies[ShouldForceV2RoutesCookieKey] === "true";
  return shouldForceV2Routes || notEmpty(migratedRouteMatcher(url));
}

export const routes = {
  applicationRedirects,
  drupalRedirects,
  dynamics,
  excludedInternals,
  internals,
  rejects,
  statics,
};
