import { PageType } from "@product/scmp-sdk";

import type { Edition } from "~/lib/edition";

export const pathname = "/home";

const getPageType = (slide: OrUndefined<"main" | "live">) => {
  switch (slide) {
    case "live":
      return PageType.Live;
    default:
      return PageType.Homepage;
  }
};

export const page = {
  route(
    query: QueryParameters<{
      edition?: Edition;
      slide?: "main" | "live";
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: getPageType(query.slide) },
    };
  },
  suffix: ["opinion", "live"],
};
