import { notEmpty } from "@product/scmp-sdk";
import { graphql, readInlineData } from "react-relay";

import { isStyleSectionContent } from "~/components/section/section-style/helpers";
import type { helpersSectionContent$key } from "~/queries/__generated__/helpersSectionContent.graphql";
import type { helpersStyleSectionContent$key } from "~/queries/__generated__/helpersStyleSectionContent.graphql";

export const checkIsStyleSectionContent = (reference: helpersStyleSectionContent$key) => {
  const section = readInlineData(
    graphql`
      fragment helpersStyleSectionContent on Section @inline {
        fullSectionPath {
          entityId
        }
      }
    `,
    reference,
  );

  const sectionEntityIds = section?.fullSectionPath?.flatMap(s => s?.entityId ?? []) ?? [];
  return isStyleSectionContent(sectionEntityIds);
};

export const checkIsSectionContent = (reference: helpersSectionContent$key) => {
  const section = readInlineData(
    graphql`
      fragment helpersSectionContent on Section @inline {
        fullSectionPath {
          entityId
        }
      }
    `,
    reference,
  );

  const sectionEntityIds = section?.fullSectionPath?.flatMap(s => s?.entityId ?? []) ?? [];
  return notEmpty(sectionEntityIds);
};
