import { PageType } from "@product/scmp-sdk";

import type { AppBarAdStatus, AppBarVariant } from "~/components/app-bar/helpers";

export const pathname = "/section";

export const page = {
  route(
    query: QueryParameters<{
      appBarAdStatus?: AppBarAdStatus;
      appBarVariant?: AppBarVariant;
      entityId: string;
      slide: Slide;
    }>,
  ) {
    return {
      pathname,
      query: { ...query, pageType: PageType.Section },
    };
  },
  slideFromSuffix(suffix?: string): Slide {
    switch (suffix) {
      case "opinion":
        return "opinion";
      case "explore":
        return "explore";
      default:
        return "main";
    }
  },
  suffix: ["explore", "opinion"] as const,
};

type Slide = "main" | "opinion" | "explore";
