import { section as sectionData } from "~/data";

import type { Section } from "./types";

export const AdCardIndexList = [2, 6, 9];
export const AdCardIndexSet = new Set(AdCardIndexList);

export const SubSectionList = ["luxury", "fashion", "beauty", "people", "lifestyle"] as const;
export type SubSection = (typeof SubSectionList)[number];

export const MaximumArticleNumberPerSection = 4;

export const SectionNameMap: Record<string, Section> = {
  beauty: {
    ...sectionData.style.beauty,
    pathname: "beauty",
  },
  fashion: {
    ...sectionData.style.fashion,
    pathname: "fashion",
  },
  lifestyle: {
    ...sectionData.style.lifestyle,
    pathname: "lifestyle",
  },
  luxury: {
    ...sectionData.style.luxury,
    pathname: "luxury",
  },
  people: {
    ...sectionData.style.people,
    pathname: "people",
  },
};
