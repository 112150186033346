import { PageType } from "@product/scmp-sdk";

import type { AppBarVariant } from "~/components/app-bar/helpers";

export const pathname = "/page";

export const page = {
  route(query: QueryParameters<{ appBarVariant?: AppBarVariant; entityId: string }>) {
    return { pathname, query: { ...query, pageType: PageType.Page } };
  },
};
