import { section as sectionData } from "~/data";

import { StyleMainSectionPath } from "./const";

export const isStyleSectionContent = (entityIds: string[]) => {
  if (entityIds.length < 2) return false;
  const [first, second] = entityIds;
  return first === sectionData.magazine.entityId && second === sectionData.style.entityId;
};

export const isStyleMainSectionBySectionPath = (sectionPath: string[]) => {
  if (sectionPath.length !== 2) return false;
  return areArraysEqual(sectionPath, StyleMainSectionPath);
};

function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) return false;
  return array1.every((item, index) => item === array2[index]);
}
