import type { AppBarAdStatus, AppBarVariant } from "~/components/app-bar/helpers";
import { section } from "~/data";

export const StyleMainSectionPath = [section.magazine.entityId, section.style.entityId];

export const MagazinesStyleVariant = "magazines-style";
export type TypeMagazinesStyleVariant = typeof MagazinesStyleVariant;

export const appBarConfiguration: { appBarAdStatus: AppBarAdStatus; appBarVariant: AppBarVariant } =
  {
    appBarAdStatus: "Enabled",
    appBarVariant: "scmp/magazines-style",
  };
