/**
 * @generated SignedSource<<cb17aaa4de0557577caf4ad226b15ac2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersSectionTheme$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersStyleSectionContent">;
  readonly " $fragmentType": "helpersSectionTheme";
};
export type helpersSectionTheme$key = {
  readonly " $data"?: helpersSectionTheme$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersSectionTheme">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersSectionTheme"
};

(node as any).hash = "7ee4e60c6cd8b7b9da6c060919bd2eb7";

export default node;
