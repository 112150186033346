import last from "lodash/last";
import { graphql, readInlineData } from "react-relay";

import { checkIsStyleSectionContent } from "~/components/section/helpers";
import { isStyleSectionContent } from "~/components/section/section-style/helpers";
import { Variant } from "~/pages/section/enums";
import type { helpersSectionTheme$key } from "~/queries/__generated__/helpersSectionTheme.graphql";

export const getVariant = (reference: helpersSectionTheme$key): Variant => {
  const section = readInlineData(
    graphql`
      fragment helpersSectionTheme on Section @inline {
        ...helpersStyleSectionContent
      }
    `,
    reference,
  );

  if (checkIsStyleSectionContent(section)) return Variant.Style;

  return Variant.Default;
};

export const getSectionRelatedSectionsQueueName = (fullSectionPath: string[]) => {
  if (fullSectionPath.length === 0) return;

  const getMagazinesStyleSubSectionRelatedQueueName = () => {
    if (fullSectionPath.length !== 4) return;
    if (!isStyleSectionContent(fullSectionPath)) return;

    return `section_categories_${fullSectionPath[2]}`;
  };

  return (
    getMagazinesStyleSubSectionRelatedQueueName() ?? `section_categories_${last(fullSectionPath)}`
  );
};
